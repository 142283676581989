// Filename - App.js

import React from "react";
import Navbar from "./components/Navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages";
import SignUp from "./pages/signup";
import Carousel from "./pages/caro";
import Vids from "./pages/vid";
import Files from "./pages/files";
import Mui from "./pages/mui";
import About from "./pages/about";
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import YourComponent from "./pages/class";

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    const isUserLoggedIn = !!sessionStorage.getItem('user');

    // Define different sets of routes based on the condition
    const loggedInRoutes = (
        <>
            <Route path="/" element={<Home />} />
            <Route path="/blogs" element={<Carousel />} />
            <Route path="/class" element={<YourComponent />} />
            <Route path="/v" element={<Vids />} />
            <Route path="/f/:path?" element={<Files />} />
            <Route path="/mui" element={<Mui />} />
            <Route path="/about" element={<About />} />
            <Route
                path="/sign-up/:id?"
                element={<SignUp />}
            />
        </>
    );

    const loggedOutRoutes = (
        <>
            <Route path="/*" element={<SignUp />} />
            <Route
                path="/sign-up/:id?"
                element={<SignUp />}
            />
        </>
    );

    // Determine which set of routes to use based on the condition
    const routesToRender = isUserLoggedIn ? loggedInRoutes : loggedOutRoutes;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                {isUserLoggedIn && <Navbar />}
                <div className="routes-container">
                    <Routes>
                        {routesToRender}
                    </Routes>
                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;