import React from "react";
import { useParams } from 'react-router-dom';
import { API_URL } from "../const";

const SignUp = () => {
    let { id } = useParams()
    if (id) {
        sessionStorage.setItem("user", id)
        localStorage.setItem("user", id)
        fetch(`${API_URL}/sign_up`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ id: id }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        // setCookie('user', id, { path: '/', expires: new Date(new Date().getTime() + 86400000), secure: true, sameSite: 'none' })
        return (
            <div>
                <h1>Sign Up Successful for {id}</h1>
            </div>
        );
    } else {
        return (<div>Hi</div>)
    }
};

export default SignUp;