import React, { useState } from "react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavLink as Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
    return (
        <>
            <BasicMenu />
        </>
    );
};



function BasicMenu() {

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        aria-controls="menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        JUB
                    </Typography>
                    {/* <Button color="inherit">Login</Button> */}
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleClose}>
                            <NavLink to="/blogs">Carousel</NavLink>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <NavLink to="/about">About</NavLink>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <NavLink to="/class">Classify</NavLink>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    );

    // return (
    //     <div>
    //         <Button
    //             id="basic-button"
    //             aria-controls={open ? 'basic-menu' : undefined}
    //             aria-haspopup="true"
    //             aria-expanded={open ? 'true' : undefined}
    //             onClick={handleClick}
    //         >
    //             Menu
    //         </Button>
    // <Menu
    //     id="basic-menu"
    //     anchorEl={anchorEl}
    //     open={open}
    //     onClose={handleClose}
    //     MenuListProps={{
    //         'aria-labelledby': 'basic-button',
    //     }}
    // >
    //     <MenuItem onClick={handleClose}>
    //         <NavLink className={'a b'} to="/blogs">Carousel</NavLink>
    //     </MenuItem>
    //     <MenuItem onClick={handleClose}>
    //         <NavLink className={'a b'} to="/about">About</NavLink>
    //     </MenuItem>
    //     <MenuItem onClick={handleClose}>Logout</MenuItem>
    // </Menu>
    //     </div>
    // );
}

export const NavLink = styled(Link)`
    color: #808080;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
        color: #4d4dff;
    }
`;

export default Navbar;