import React, { useState, useEffect, useCallback } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { API_URL } from "../const";
import { useCookies } from 'react-cookie';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [cookies] = useCookies(['user'])

  const url = `${API_URL}/file/`;

  // This useEffect handles data fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${url}`, {credentials: 'include'});
        const data = await response.json();
        setApiData(data.keys);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [url]);

  const goToNext = useCallback(() => {
    if (apiData.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % apiData.length);
    }
  }, [apiData]);

  const goToPrev = useCallback(() => {
    if (apiData.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + apiData.length) % apiData.length);
    }
  }, [apiData]);

  // This useEffect adds the keyup event listener and cleans up on unmount
  useEffect(() => {
    const handleKeyUp = (event) => {
      console.log(event);
      if (event.key === 'Enter') {
        console.log(isExpanded);
        setIsExpanded(!isExpanded);
      }

      if (event.code === 'Space') {
        setIsPaused(!isPaused);
      }

      if (event.code === 'ArrowRight') {
        goToNext()
      }

      if (event.code === 'ArrowLeft') {
        goToPrev()
      }
    };

    document.addEventListener('keyup', handleKeyUp);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isExpanded, isPaused, currentIndex, goToNext, goToPrev]);

  // // This useEffect handles updating the image source
  // useEffect(() => {
  //   if (apiData.length > 0) {
  //     setImageSrc(url + apiData[currentIndex].key);
  //   }
  // }, [currentIndex, apiData]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (apiData.length > 0) {
          setImageSrc(`${url}${apiData[currentIndex]}`)
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };
  
    fetchImage();
  }, [currentIndex, apiData, url, cookies.user]);

  // This useEffect handles auto-scrolling
  useEffect(() => {
    const autoScroll = () => {
      if (!isPaused) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % apiData.length);
      }
    };

    const interval = setInterval(autoScroll, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, apiData.length, isPaused]);

  const togglePause = () => {
    setIsPaused((prevIsPaused) => !prevIsPaused);
  };

  const handleContentClick = () => {
    if (apiData.length === 0) {
      return;
    }
    console.log(`Clicked on content: ${apiData[currentIndex].key}`);
    setIsExpanded(!isExpanded);
  };

  let name = cookies.user || 'a';

  return (
    <div className="number-carousel">
      <Typography variant="h1" component="h2">
        Hi {name}
      </Typography>
      <div className="current-number" onClick={handleContentClick}>
        {imageSrc ? (
          <img
            className="carousel-image"
            src={imageSrc}
            alt={`${currentIndex + 1}`}
            style={{ maxWidth: isExpanded ? '100%' : '500px', maxHeight: isExpanded ? '100%' : '500px', top: "100px" }}
          />
        ) : (
          <p>No image available</p>
        )}
      </div>
      <div className="caro-buttons">
        <Button onClick={goToPrev}>Previous</Button>
        <Button onClick={togglePause}>
          {isPaused ? "Resume" : "Pause"}
        </Button>
        <Button onClick={goToNext}>Next</Button>
      </div>
    </div>
  );
};

export default Carousel;
