import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { API_URL } from "../const";


// const url = "https://worker.gregg-h-schoen.workers.dev/file/";
const url = `${API_URL}/file`;

function countOccurrences(str, char) {
    return str.split(char).length - 1;
}

function getParentFolders(data, prefix) {
    const folderDepth = countOccurrences(prefix, '/')
    
    let folders = new Set()
    data.keys.forEach((object) => {
        const splitKey = object.split('/');
    
        if (splitKey.length - 1 > folderDepth) {
            folders.add(splitKey[folderDepth]);
        }
    });
    
    return Array.from(folders)
}

function ActiveLastBreadcrumb({ path }) {
    // Split the path into an array
    const pathArray = path.split('/').filter(Boolean); // filter to remove empty strings

    // If there are no breadcrumbs, render nothing
    if (pathArray.length === 0) {
        return null;
    }

    console.log(pathArray);

    return (
        <Breadcrumbs aria-label="breadcrumb">
            {pathArray.map((breadcrumb, index) => {
                const isLast = index === pathArray.length - 1;

                return isLast ? (
                    <Typography key={index} color="white">
                        {breadcrumb}
                    </Typography>
                ) : (
                    <Link key={index} to={`/f/${pathArray.slice(1, index + 1).join('/')}`}>
                        {breadcrumb}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
}

const Files = () => {
    function filterObjectsByPrefix(data, prefix) {
        console.log(data)
        return data.keys.filter(object => {
            // Check if the object's key matches the pattern "prefix/filename.extension"
            const keyMatchesPattern = new RegExp(`^${prefix}[^/]+\\.[^/]+$`).test(object);
            // Return true if the key matches the specified pattern
            console.log(object)
            console.log(keyMatchesPattern)
            return keyMatchesPattern;
        }).map(obj => obj.split("/").slice(1).join("/"));
    }


    let { path } = useParams()
    const [fileList, setFileList] = useState([])
    const [folderList, setFolderList] = useState([])
    const user = sessionStorage.getItem('user')

    let filePath = url;

    let prefix = `${user}/`

    if (path !== undefined) {
        filePath += path
        prefix += path + '/'
    }

    filePath += `?user=${user}`

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(filePath);
                const fdata = await response.json();
                console.log(`prefix ${prefix}`)
                let data = filterObjectsByPrefix(fdata, prefix)

                setFileList(data);
                setFolderList(getParentFolders(fdata, prefix))
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [filePath, prefix]);



    return (<div>
        <ActiveLastBreadcrumb path={prefix} />
        <ul>
            {fileList.map((obj, index) => (
                <li key={index} >
                    <Link to={`${url}/${obj}?user=${user}`}>{obj}</Link>
                </li>
            ))}
            {folderList.map((obj, index) => (
                <li key={index} >
                    <Link to={`/f/${obj}`}>{obj}</Link>
                </li>
            ))}
        </ul>
    </div>)
}

export default Files;
