import React, { useState, useEffect } from "react";

const Home = (properties) => {
    const [data, setData] = useState([]);
    const fetchInfo = async () => {
        const res = await fetch("https://api.weather.gov/zones/public/WAZ558/forecast", {
        });
        const d = await res.json();
        return setData(d);
    }

    useEffect(() => {
        fetchInfo();
    }, []);



    return (
        <div>
            <h1>Welcome to Maddie's page</h1>
            <h2>{JSON.stringify(data.properties, null, 2)}</h2>
        </div>
    );
};

export default Home;