import React, { useState, useEffect } from "react";
import { API_URL } from "../const";
import { useCookies } from 'react-cookie';

const api_url = `${API_URL}/file`



const About = () => {
    const [showAbout, setShowAbout] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [cookies] = useCookies(['user'])
    console.log(cookies)

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowAbout(true);
    };

    const handleCloseModal = () => {
        setShowAbout(false);
    };

    const Modal = ({ imageUrl, onClick }) => {
        return (
            <div className="modal-overlay" onClick={onClick}>
                <div className="modal">
                    <img src={imageUrl} alt="Big boy" />
                </div>
            </div>
        );
    };

    useEffect(() => {
        const fetchImages = async (objects) => {
            const imageUrls = [];
            for (const key of objects.keys) {
                if (key.endsWith('.jpeg') || key.endsWith('.png') || key.endsWith('.jpg')) {
                    imageUrls.push(`${api_url}/${key}`);
                }
            }
            return imageUrls;
        };

        const fetchData = async () => {
            try {
                const response = await fetch(api_url, { credentials: 'include' });
                const file_keys = await response.json();

                let x = await fetchImages(file_keys)
                setImageUrls(x)
            } catch (error) {
                console.error("Error fetching data:", error);
            }


        };

        fetchData();
    }, [cookies.user]);



    return (
        <div id="gallery">
            {imageUrls.map((url, index) => (
                <div className="item" key={index}>
                    <img
                        alt=''
                        key={index}
                        src={url}
                        onClick={() => handleImageClick(url)}
                    />
                </div>
            ))}
            {showAbout && (
                <Modal imageUrl={selectedImage} onClick={handleCloseModal} />
            )}
        </div>
    );
};

export default About;
