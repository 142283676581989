import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const url = "https://worker.gregg-h-schoen.workers.dev/file/";

const Vids = () => {
    const [fileList, setFileList] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://worker.gregg-h-schoen.workers.dev/file/video/", {
                    method: 'get',
                    headers: { 'user': sessionStorage.getItem('user') }
                });
                const data = await response.json();
                setFileList(data.objects);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);



    return (<div>

        <ul>
        {fileList.map((obj, index) => (
                        <li key={index} >
                            <Link to={`${url}${obj.key}`}>{obj.key}</Link>
                        </li>
                    ))}
        </ul>
    </div>)
}

export default Vids;
