import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';

const YourComponent = () => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [responseBody, setResponseBody] = useState(null);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    const url = URL.createObjectURL(selectedFile);
    setImageUrl(url);
    
    console.log(`originalFile size ${selectedFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    const compressedFile = await imageCompression(selectedFile, options);
    console.log(`comp file size ${compressedFile.size / 1024 / 1024} MB`);
    setFile(compressedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file.');
      return;
    }

    const blob = await file.arrayBuffer();
    
    const res = await fetch("https://worker.gregg-h-schoen.workers.dev/classify/", {
      method: 'POST',
      body: JSON.stringify({ image: [...new Uint8Array(blob)] }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!res.ok) {
      console.error('Failed to upload file:', res.status, res.statusText);
      return;
    }

    const data = await res.json();
    setResponseBody(data);

    // Process the response
  };

  return (
    <div className='img-classification'>
      <div>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
      </div>
      
      {imageUrl && (
        <div>
          <h2>Uploaded Image:</h2>
          <img src={imageUrl} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '300px' }} />
        </div>
      )}
      
      {responseBody && (
        <div>
          <h2>Response Body:</h2>
          <pre>{JSON.stringify(responseBody, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default YourComponent;
